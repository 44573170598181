<div class="container" *ngIf="textslist">
  <section class="row">
    <div class="col-sm-12">
      <h1 class="page-header">Texts</h1>
      <div class="filter-button-group">
        <span data-filter="all" class="is-checked"><a *ngIf="settings">{{ settings.fields.textcatAll }}</a></span>
        <span data-filter=".Interviews"><a *ngIf="settings">{{ settings.fields.textcatInterviews }}</a></span>
        <span data-filter=".Essays"><a *ngIf="settings">{{ settings.fields.textcatEssays }}</a></span>
      </div>
    </div>
  </section>
  <section class="texts row filter-items {{ text.fields.textCat}}" *ngFor="let text of textslist">
    <div class="col-sm-12">
      <h2>{{ text.fields.name}}</h2>
      <div *ngFor="let files of text.fields.textsFile" class="text-item">
        <a href="{{ files.fields.file.url }}">{{ files.fields.title }}</a>
      </div>
    </div>
  </section>


  <!-- use https://isotope.metafizzy.co/ OR https://vestride.github.io/Shuffle/ for content filtering -->
