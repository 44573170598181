<div class="container" *ngIf="news">
  <section class="row">
    <div class="col-sm-12">
      <h1 class="page-header details">{{ news.fields.title }}</h1>
    </div>
  </section>

  <section class="row carousel-content">
    <div class="col-sm-12">
      <mdb-carousel class="carousel slide carousel-fade" [type]="'carousel-thumbnails'" [animation]="'fade'">
        <mdb-carousel-item *ngFor="let gallery of news.fields.slideshowGallery">
          <div><img class="d-block w-100" src="{{ gallery.fields.file.url }}" alt="First slide"></div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </section>
  
  <section class="row padding-bottom">
    <div class="col-md-8 col-sm-12 intro">
      <h2>{{ news.fields.title }}</h2>
      <div class="subtitle" [innerHTML]="news.fields.subtitle2 | mdToHtml" *ngIf="news.fields.subtitle2"></div>
      <div [innerHTML]="news.fields.introContent | mdToHtml"></div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div class="sharethis-inline-share-buttons"></div>
    </div>
    <div class="col-sm-12 description" *ngIf="news.fields.mainContent">
      <div [innerHTML]="news.fields.mainContent | mdToHtml"></div>
    </div>
    <div class="col-sm-12 video" *ngIf="news.fields.videoTitle">
      <h5>{{ news.fields.videoTitle }}</h5>
      <div *ngFor="let video of news.fields.videoLink" class="videoWrapper">
        <iframe [src]="video | safePipe " width="100%" height="380" frameborder="0" webkitallowfullscreen=""
          mozallowfullscreen="" allowfullscreen=""></iframe>
      </div>
    </div>
  </section>
</div>


