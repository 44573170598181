export const environment = {
	production: false,
	NEXT_PUBLIC_ALGOLIA_APP_ID: "EPON30YTMF",
	NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY: "1059a52bc3b6bc3c0233129405d8a7c1",
	ALGOLIA_SEARCH_ADMIN_KEY: "179d72d9fdae48664b2f557500857dd8",
	contentful: {
		spaceId: "fbgdsh01ydo0",
		token: "jkCK9BH0vBfo1MnHK2OqaZMWfOCo04GkzpbDcdzjHyM",
		staging_token: "XPTcJ_7JNoS07awblsZxjrsdICg1ezqlCA7a7mt5ZKw"
		// jkCK9BH0vBfo1MnHK2OqaZMWfOCo04GkzpbDcdzjHyM PRODUCTION
	}
};
