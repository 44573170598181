import { Component, OnInit, ɵConsole } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-texts',
  templateUrl: './texts.component.html'
})
export class TextsComponent implements OnInit {
	settings: Entry<any>;
	textslist: Entry<any>[] = [];
	constructor(
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	
	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		this.contentfulService.getList(locale, 'texts').then((texts) => {
			this.textslist = texts;
			// console.log(this.textslist);
			
			$('.filter-button-group').on( 'click', 'span', function() {
				var filterValue = $(this).attr('data-filter');
				if(filterValue === "all"){
					$('.filter-items').fadeIn();
				}else{
					$('.filter-items').fadeOut();
					$(filterValue).fadeIn();
				}
				$('.filter-button-group span').removeClass('is-checked');
				$(this).addClass('is-checked');
			});
		});
		this.contentfulService.getPage(locale, 'settings', '2ExNEl8vKOI8qB6MbABmEn')
		.then((settings) => {
			this.settings = settings;
			// console.log(this.settings);
		});

		this.title.setTitle('Texts | Miguel Chevalier');
		this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
		
	}
	
}