import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';


import { NgAisModule } from 'angular-instantsearch';

import { ContentfulService } from './contentful.service';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicationListComponent } from './publication-list/publication-list.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { TextsComponent } from './texts/texts.component';
import { SafePipePipe } from './safe-pipe.pipe';
import { MdToHtmlPipe } from './md-to-html.pipe';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { BiographyPageComponent } from './biography-page/biography-page.component';
import { WorksListComponent } from './works-list/works-list.component';
import { WorksDetailsComponent } from './works-details/works-details.component';
import { HeaderComponent } from './header/header.component';
import { HomepageComponent } from './homepage/homepage.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { VideoListComponent } from './video-list/video-list.component';
import { VideoDetailsComponent } from './video-details/video-details.component';
import { FooterComponent } from './footer/footer.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';

import { SearchComponent } from './search/search.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';





@NgModule({
  declarations: [
    AppComponent,
    PublicationListComponent,
    NewsListComponent,
    NewsDetailsComponent,
    TextsComponent,
    SafePipePipe,
    MdToHtmlPipe,
    ContactPageComponent,
    BiographyPageComponent,
    WorksListComponent,
    WorksDetailsComponent,
    HeaderComponent,
    HomepageComponent,
    FooterComponent,
    SearchComponent,
    PagenotfoundComponent,
    VideoListComponent,
    VideoDetailsComponent,
  ],
  imports: [
    BrowserModule,
    NgAisModule.forRoot(),
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    FontAwesomeModule,
    LazyLoadImageModule,
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [
    ContentfulService,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
