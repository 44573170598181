import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
	selector: 'app-publication-list',
	templateUrl: './publication-list.component.html',
	styleUrls: ['./publication-list.component.scss']
})
export class PublicationListComponent implements OnInit {
	defaultImage = '../../assets/img/lazyload.png';
	settings: Entry<any>;
	publications: Entry<any>[] = [];
	constructor(
		private router: Router,
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		// console.log(locale);

		// this.contentfulService.getList(locale, 'title').then(publications => this.publications = publications);
		// $('.filter-button-group').on( 'click', 'span', function() {
		// 	var filterValue = $(this).attr('data-filter');
		// 	if(filterValue === "all"){
		// 		$('.filter-items').fadeIn();
		// 	}else{
		// 		$('.filter-items').fadeOut();
		// 		$(filterValue).fadeIn();
		// 	}
		// 	$('.filter-button-group span').removeClass('is-checked');
		// 	$(this).addClass('is-checked');
		// });

		this.contentfulService.getList(locale, 'title').then((title) => {
			this.publications = title;
			console.log(this.publications);
			
			$('.filter-button-group').on( 'click', 'span', function() {
				var filterValue = $(this).attr('data-filter');
				if(filterValue === "all"){
					$('.filter-items').fadeIn();
				}else{
					$('.filter-items').fadeOut();
					$(filterValue).fadeIn();
				}
				$('.filter-button-group span').removeClass('is-checked');
				$(this).addClass('is-checked');
			});
		});

		this.contentfulService.getPage(locale, 'settings', '2ExNEl8vKOI8qB6MbABmEn')
		.then((settings) => {
			this.settings = settings;
			// console.log(this.settings);
		});
		this.title.setTitle('Publications | Miguel Chevalier');
		this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
	}
}