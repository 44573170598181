import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  settings: Entry<any>;
  constructor(private cookie: CookieService, private contentfulService: ContentfulService) { 

  }

  public locale: string;

  langSelect(lang){
    this.cookie.set('locale', lang);
    // console.log('cookie stored. lang = ' + this.cookie.get('locale'));
    location.reload();
  }

  ngOnInit(): void {
    // set for selected on page
    this.locale = this.cookie.get('locale');
    var locale = this.cookie.get('locale');
    // Get page settings
		this.contentfulService.getPage(locale, 'settings', '2ExNEl8vKOI8qB6MbABmEn')
		.then((settings) => {
			this.settings = settings;
			// console.log(this.settings);
		});
  }
  
}
