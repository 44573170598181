import { Component } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch(
	'EPON30YTMF',
	'1059a52bc3b6bc3c0233129405d8a7c1'
);

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent {
	defaultImage = '../../assets/img/lazyload.png';
	config = {
		indexName: 'miguel-chevalier',
		searchClient, 
		routing: true
	};
}

// https://www.contentful.com/blog/2021/07/02/add-algolia-instantsearch-to-nextjs-app/
// https://www.contentful.com/marketplace/webhook/algolia/