import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss']
})
export class VideoDetailsComponent implements OnInit {
	// defaultImage = '../../assets/img/lazyload.png';
	videos: Entry<any>;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		if (this.cookie.get('locale') === undefined || !this.cookie.get('locale')) {
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		const videosId = this.route.snapshot.paramMap.get('id');
		this.contentfulService.getDetail(locale, 'videos', videosId)
			.then((videos) => {
				this.videos = videos;
				// console.log(this.videos);
				var videoTitle = this.videos.fields.title;
				this.title.setTitle(videoTitle + ' | Miguel Chevalier');
				this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
			});

	}
	goToList() {
		this.router.navigate(['/videos']);
	}

}



