import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-news-details',
	templateUrl: './news-details.component.html'
})
export class NewsDetailsComponent implements OnInit {

	news: Entry<any>;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		const newsId = this.route.snapshot.paramMap.get('id');//change this to url
		this.contentfulService.getDetail(locale, 'news', newsId)
			.then((news) => {
				this.news = news;
				// console.log(this.news);
				var title = this.news.fields.title;
				this.title.setTitle(title + ' | Miguel Chevalier');
				this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
			});

	}
	goToList() {
		this.router.navigate(['/newslist']);
	}
}



