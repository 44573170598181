import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'] 
})
export class VideoListComponent implements OnInit {
	defaultImage = '../../assets/img/lazyload.png';
	videolist: Entry<any>[] = [];
	settings: Entry<any>;
	constructor(
		private router: Router,
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		if (this.cookie.get('locale') === undefined || !this.cookie.get('locale')) {
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		this.contentfulService.getList(locale, 'videos')
			.then((videolist) => {
				this.videolist = videolist;
				// console.log(videolist);
			});

		this.title.setTitle('Videos | Miguel Chevalier');
		this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
		
		// Get page settings
		this.contentfulService.getPage(locale, 'settings', '2ExNEl8vKOI8qB6MbABmEn')
		.then((settings) => {
			this.settings = settings;
			// console.log(this.settings);
		});
	}
	goToDetailsPage(slug) {
		this.router.navigate(['/videos', slug]);
	}
}

