import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html'
})
export class ContactPageComponent implements OnInit {

  contact: Entry<any>;
  contactRepeater: Entry<any>[] = [];
	constructor(
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
    private meta: Meta
	) { }
	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		const contactId = '1QQ55DXnjyiYSg52gDJZEe';
		this.contentfulService.getPage(locale, 'contactPage', contactId)
			.then((contact) => {
				this.contact = contact;
				// console.log(this.contact);
      });
    this.contentfulService.getList(locale, 'contactRepeater')
			.then((contactRepeater) => {
				this.contactRepeater = contactRepeater;
				// console.log(contactRepeater);
			});

		this.title.setTitle('Contact | Miguel Chevalier');
		this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
	}

	htmlToRichText(richText) {
		if (richText === undefined || richText === null || richText.nodeType !== 'document') {
		  return '<p>Error</p>';
		}
		return documentToHtmlString(richText);
	}

}



