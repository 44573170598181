<div class="container" *ngIf="contact">
  <section class="row">
    <div class="col-sm-12">
      <h1 class="page-header page-template">{{ contact.fields.typeTitle }}</h1>
    </div>
  </section>
  <section class="row">
    <div class="col-sm-12">
      <h2 class="page-template">ATELIER LA FABRIKA / PARIS</h2>
    </div>
    <div class="col-xs-12 col-sm-6 rtee" [innerHTML]="htmlToRichText(contact.fields.mainAddress)"></div>
    <div class="col-xs-12 col-sm-6 rtee" [innerHTML]="htmlToRichText(contact.fields.studioAddress)"></div>
    <div class="col-sm-12 contact-image">
      <img src="{{ contact.fields.mainImage.fields.file.url }}" />
    </div>
  </section>
  <section class="row padding-bottom">
    <div class="col-sm-12">
      <h2 class="page-template">{{ contact.fields.artisticTitle }}</h2>
    </div>
    <div class="col-xs-12 col-sm-6 rtee extra" [innerHTML]="htmlToRichText(address.fields.galleryAddress)" *ngFor="let address of contactRepeater"></div>
  </section>
</div>
