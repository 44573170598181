
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit { 
  defaultImage = '../../assets/img/lazyload.png'; 
  settings: Entry<any>;
  config = {
		indexName: 'miguel-chevalier'
	};
  searchUrl: string = '';
  setValue() {
    this.router.navigateByUrl('/s?query=' + this.searchUrl);
  }
  constructor(
    private cookie: CookieService,
    private contentfulService: ContentfulService,
    private router: Router
  ) {

  }

  public locale: string;

  langSelect(lang) {
    this.cookie.set('locale', lang);
    // console.log('cookie stored. lang = ' + this.cookie.get('locale'));
    window.location.reload();
  }

  openMenu() {
    console.log('clicked');
    var menuId = document.getElementById('menu-icon');
    var mainMenu = document.getElementById('main-menu');
    menuId.classList.toggle('open');
    mainMenu.classList.toggle('is-open');
  }

  closeMenu() {
    console.log('close');
    var menuId = document.getElementById('menu-icon');
    var mainMenu = document.getElementById('main-menu');
    menuId.classList.toggle('open');
    mainMenu.classList.toggle('is-open');
  }

  ngOnInit(): void {

    // if no lang selected language
    if (this.cookie.get('locale') === undefined || !this.cookie.get('locale')) {
      //set default 
      this.cookie.set('locale', 'fr');
      // console.log(this.cookie.get('locale'))
    }

    // set for selected on page
    this.locale = this.cookie.get('locale');
    var locale = this.cookie.get('locale');
    // Get page settings
    this.contentfulService.getPage(locale, 'settings', '2ExNEl8vKOI8qB6MbABmEn')
      .then((settings) => {
        this.settings = settings;
        // console.log(this.settings);
      });
  }

}