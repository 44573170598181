<header id="navbar" role="banner" class="navbar container navbar-default" *ngIf="settings">
  <div class="row" id="navbar">
    <div class="col-auto mr-auto left">
      <div class="img-col">
        <a href="index.html" id="logo-title"><img src="../../assets/img/logo_MC_carre.gif" alt="Home"></a>
      </div>
      <div class="text-col">
        <a href="index.html" id="logo-title2"><img alt="Logo Title" class="logo-header" src="../../assets/img/logo_MC_nom.gif"></a>
        <div #lang id="lang">
          <a data-lang="en-US" [ngClass]="{'active': locale == 'en-US'}"
            (click)="langSelect(langEn.getAttribute('data-lang'))" #langEn>En </a> /
          <a data-lang="fr" [ngClass]="{'active': locale == 'fr'}"
            (click)="langSelect(langFr.getAttribute('data-lang'))" #langFr> Fr</a>
        </div>
        <ul id="main-menu">
          <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()"
              routerLink="/newslist/">{{ settings.fields.menuNews }}</a></li>
          <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()"
              routerLink="/works/">{{ settings.fields.menuWorks }}</a></li>
          <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()"
              routerLink="/biography/">{{ settings.fields.menuBiography }}</a></li>
          <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()"
              routerLink="/texts/">{{ settings.fields.menuTexts }}</a></li>
          <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()"
              routerLink="/publications/">{{ settings.fields.menuPublications }}</a></li>
          <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()"
              routerLink="/videos/">Videos</a></li>
          <li class="main-menu-item"><a routerLinkActive="active" (click)="closeMenu()"
              routerLink="/contact/">{{ settings.fields.menuContact }}</a></li>
          <li class="main-menu-item"><a href="https://shop.miguel-chevalier.com" target="_blank">Shop</a></li>
        </ul>
      </div>
    </div>
    <div class="col-auto right">
      <div class="search-algolia">
        <div class="ais-SearchBox">
          <form novalidate="" class="ais-SearchBox-form">
            <input autocapitalize="off" autocorrect="off" role="textbox" spellcheck="false" type="text" class="ais-SearchBox-input" placeholder="Search" id="searchUrl" name="searchUrl" [(ngModel)]="searchUrl">
            <button type="submit" class="ais-SearchBox-submit" title="Submit" (click)="setValue()">
              <svg viewBox="0 0 40 40" width="40" height="40" ng-reflect-ng-class="ais-SearchBox-submitIcon" class="ais-SearchBox-submitIcon"><path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path></svg>
            </button>  
          </form>
        </div>
      </div>
      <div class="link-social">
        <a href="http://vimeo.com/channels/miguelchevalier" target="_blank"><img src="../../assets/img/vimeo-icon.svg" alt="vimeo"></a>
        <a href="http://www.facebook.com/Miguel.Chevalier.studio" target="_blank"><img src="../../assets/img/facebook-icon.svg" alt="facebook"></a>
        <a href="http://twitter.com/ChevalierMiguel" target="_blank"><img src="../../assets/img/twitter-icon.svg" alt="twitter"></a>
        <a href="https://www.youtube.com/user/claudemossessian" target="_blank"><img src="../../assets/img/youtube-icon.svg" alt="youtube"></a>
        <a href="https://instagram.com/miguel_chevalier/" target="_blank"><img src="../../assets/img/instagram-icon.svg" alt="instagram"></a>
      </div>
    </div>
    <div id="menu-icon" (click)="openMenu()"><span class="c-hamburger"><span></span></span></div>
  </div>
</header>
