<div class="container">
    
  <mdb-carousel [isControls]="true" [animation]="'slide'" class="section" id="homepageCarousel" *ngIf="homeCarousel">
    <mdb-carousel-item *ngFor="let carousel of homeCarousel">
      <ng-template [ngIf]="carousel.fields.link" [ngIfElse]="externalLink">
        <div class="row">
          <div class="col-sm-12">
            <a routerLink="{{ carousel.fields.link }}"><img class="d-block w-100" src="{{ carousel.fields.galleryImage.fields.file.url }}" alt="First slide"></a>
          </div>
          <div class="col-sm-12">
            <a routerLink="{{ carousel.fields.link }}"><h5>{{ carousel.fields.title }}</h5></a>
            <a routerLink="{{ carousel.fields.link }}"><p>{{ carousel.fields.location }}</p></a>
          </div>
        </div>
      </ng-template>
      <ng-template #externalLink>
        <div class="row">
          <div class="col-sm-12">
            <a target="_blank" href="{{ carousel.fields.externalLink }}"><img class="d-block w-100" src="{{ carousel.fields.galleryImage.fields.file.url }}" alt="First slide"></a>
          </div>
          <div class="col-sm-12">
            <a target="_blank" href="{{ carousel.fields.externalLink }}"><h5>{{ carousel.fields.title }}</h5></a>
            <a target="_blank" href="{{ carousel.fields.externalLink }}"><p>{{ carousel.fields.location }}</p></a>
          </div>
        </div>
      </ng-template>
    </mdb-carousel-item>
  </mdb-carousel>
  <section class="padding-bottom">
    <div class="row" *ngIf="works">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 works-cc" *ngFor="let work of works">
        <a routerLink="/work/{{ work.fields.slug }}"><img src="{{ work.fields.mainImage.fields.file.url }}" /></a>
        <a routerLink="/work/{{ work.fields.slug }}"><h5>{{ work.fields.title }}</h5></a>
        <a routerLink="/work/{{ work.fields.slug }}"><p>{{ work.fields.location }}</p></a>
      </div>
    </div>
    <div class="row" *ngIf="settings">
      <div class="col-sm-12 text-center"><br><br><a routerLink="/works/"><b><u>{{ settings.fields.seeMore }}</u></b></a></div>
    </div>
  </section>
</div>