<div class="container" *ngIf="videos">
    <section class="row">
      <div class="col-sm-12">
        <h1 class="page-header details">{{ videos.fields.title }}</h1>
      </div>
    </section>
    
    <section class="row padding-bottom">
      <div class="col-md-8 col-sm-12 intro">
        <div class="subtitle" [innerHTML]="videos.fields.subTitle | mdToHtml" *ngIf="videos.fields.subTitle"></div>
      </div>
      <div class="col-sm-12 video" *ngIf="videos.fields.title">
        <div *ngFor="let video of videos.fields.videoLink" class="videoWrapper">
          <iframe [src]="video | safePipe " width="100%" height="380" frameborder="0" webkitallowfullscreen=""
            mozallowfullscreen="" allowfullscreen=""></iframe>
        </div>
      </div>
      
      <div class="col-sm-12 description" *ngIf="videos.fields.mainContent">
        <div [innerHTML]="videos.fields.mainContent | mdToHtml"></div>
      </div>
      
    </section>
  </div>
  