<div class="container" *ngIf="biography">
  <section class="row">
    <div class="col-sm-12">
      <h1 class="page-header page-template">{{ biography.fields.typeTitle }}</h1>
    </div>
  </section>
  <section class="row">
    <div class="col-sm-12">
      
      <h2 class="page-template">portraits</h2>
      <div class="image-row">
        <img src="{{ portraits.fields.file.url }}" *ngFor="let portraits of biography.fields.portraits" />
      </div>
    </div>
  </section>
  <section class="row padding-bottom">
    <div class="col-sm-12">
      <div class="h2 page-template">
        BIOGRAPHIE RÉSUMÉE
        <div *ngFor="let resume of biography.fields.resume" class="resume">
          <div class="download-file clear-block">
            <img class="file-icon" alt="download pdf" src="../../assets/img/application-pdf.png">
            <a href="{{ resume.fields.file.url }}">{{ resume.fields.title }}</a>
            
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="rte" [innerHTML]="htmlToRichText(biography.fields.biographyText)"></div>
      <p>&nbsp;</p>
      <div *ngIf="soloExhibitions">
        <b>{{ biography.fields.soloExhibition }}</b>
        <div *ngFor="let content of soloExhibitions" class="content-repeater">
          <div class="year">{{content.fields.year}}</div>
          <div class="rteindent1" [innerHTML]="htmlToRichText(content.fields.content)" ></div>
        </div>
      </div>
      <p>&nbsp;</p>
      <div *ngIf="groupExhibitions">
        <b>{{ biography.fields.groupExhibition }}</b>
        <div *ngFor="let content of groupExhibitions" class="content-repeater">
          <div class="year">{{content.fields.year}}</div>
          <div class="rteindent1" [innerHTML]="htmlToRichText(content.fields.content)" ></div>
        </div>
      </div>
      <p>&nbsp;</p>
      <div *ngIf="archDesigns">
        <b>{{ biography.fields.archDesign }}</b>
        <div *ngFor="let content of archDesigns" class="content-repeater">
          <div class="year">{{content.fields.year}}</div>
          <div class="rteindent1" [innerHTML]="htmlToRichText(content.fields.content)" ></div>
        </div>
      </div>
      <p>&nbsp;</p>
      <b>{{ biography.fields.permanentCollection }} </b> <br>
      <div class="rte" [innerHTML]="htmlToRichText(biography.fields.permanentCollections)"></div>
      <p>&nbsp;</p>
      <div *ngIf="monographs">
        <b>{{ biography.fields.monograph }} </b>
        <div *ngFor="let content of monographs" class="content-repeater">
          <div class="year">{{content.fields.year}}</div>
          <div class="rteindent1" [innerHTML]="htmlToRichText(content.fields.content)" ></div>
        </div>
      </div>

    </div>
  </section>
</div>
