<div class="container" *ngIf="publications">
  <section class="row">
    <div class="col-sm-12">
      <h1 class="page-header">Publications</h1>
      <div class="filter-button-group">
        <span data-filter="all" class="is-checked"><a *ngIf="settings">{{ settings.fields.pubcatAll }}</a></span>
        <span data-filter=".Monographs"><a *ngIf="settings">{{ settings.fields.pubcatMonographs }}</a></span>
        <span data-filter=".Catalogs"><a *ngIf="settings">{{ settings.fields.pubcatCatalogs }}</a></span>
      </div>
    </div>
  </section>
  <section class="underline row filter-items {{ publication.fields.publicationCat }}" *ngFor="let publication of publications">
    <div class="col-xs-12 col-sm-5">
      <img [defaultImage]="defaultImage" [lazyLoad]="publication.fields.image.fields.file.url" />
    </div>
    <div class="col-xs-12 col-sm-7">
      <h5>{{ publication.fields.title}}</h5>
      <div [innerHTML]="publication.fields.content | mdToHtml"></div>
      <div *ngFor="let files of publication.fields.pdfFile" class="text-item">
        <strong>pdf: </strong> <a href="{{ files.fields.file.url }}">{{ files.fields.title }}</a>
      </div>
    </div>
  </section>
</div>


<!-- use https://isotope.metafizzy.co/ OR https://vestride.github.io/Shuffle/ for content filtering -->