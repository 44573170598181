<div class="container">
  <section class="row search">
    <div class="col-sm-12">
      <ais-instantsearch 
        [config]="config"
        placeholder="Search..." >
        <ais-search-box #searchInput></ais-search-box>
        <ais-hits>
          <ng-template let-hits="hits" let-results="results">
            <div class="ais-Hits-list row" *ngIf="results.query && results.query.length > 0">
              <div *ngFor="let hit of hits" class="ais-Hits-item col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <a routerLink="/work/{{ hit.slug }}"><img [defaultImage]="defaultImage" [lazyLoad]="hit.mainImage" alt="search image" /></a>
                <a routerLink="/work/{{ hit.slug }}"><h5>{{ hit.title }}</h5> </a>
                <a routerLink="/work/{{ hit.slug }}"> <p>{{ hit.location }}</p> </a>
              </div>
            </div>
          </ng-template>
        </ais-hits>
      </ais-instantsearch>
    </div>
  </section>
</div>
