import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { environment } from '../environments/environment';

@Injectable()
export class ContentfulService {
  private client = createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.staging_token, // token for prod, staging_token for staging
    environment: 'staging' // staging/master
  });
  constructor() { }
  getList(locale, contentType, query?: object): Promise<Entry<any>[]> {
    return this.client.getEntries(Object.assign({
      content_type: contentType 
    }, {"order": "sys.createdAt", 'locale': locale}))
      .then(res => res.items);
  }
  getListDESC(locale, contentType, limit, query?: object): Promise<Entry<any>[]> {
    return this.client.getEntries(Object.assign({
      content_type: contentType 
    }, {"order": "-fields.year", 'locale': locale, 'limit': limit}))
      .then(res => res.items);
  }
  getListWork(locale, contentType, limit, query?: object): Promise<Entry<any>[]> {
    return this.client.getEntries(Object.assign({
      content_type: contentType 
    }, {"order": "-fields.displayYear", 'locale': locale, 'limit': limit}))
      .then(res => res.items);
  }
  getDetail(locale, contentType, titleId): Promise<Entry<any>> {
    return this.client.getEntries(Object.assign({
     content_type: contentType 
    }, {'fields.slug': titleId, 'locale': locale}))
      .then(res => res.items[0]);
  }
  getPage(locale, contentType, titleId): Promise<Entry<any>> {
    return this.client.getEntries(Object.assign({
     content_type: contentType 
    }, {'sys.id': titleId, 'locale': locale}))
      .then(res => res.items[0]);
  }
  getListDO(locale, contentType, query?: object): Promise<Entry<any>[]> {
    return this.client.getEntries(Object.assign({
      content_type: contentType 
    }, {"order": "-fields.displayOrder", 'locale': locale}))
      .then(res => res.items);
  }

  getSearch(locale, contentType, limit, query?: object): Promise<Entry<any>[]> {
    return this.client.getEntries(Object.assign({
      content_type: contentType 
    }, {
      'order': '-fields.displayYear', 
      'locale': locale, 
      'limit': limit,
      
    }))
      .then(res => res.items);
  }
}

