<div class="container" *ngIf="newslist">
  <section class="row">
    <div class="col-sm-12">
        <h1 class="page-header"><h2>{{ settings.fields.menuNews }}</h2></h1>
    </div>
  </section>
  <section class="underline row" *ngFor="let news of newslist">
    <div class="col-xs-12 col-sm-5">
      <a routerLink="/news/{{ news.fields.slug }}"><img alt="news image" [defaultImage]="defaultImage" [lazyLoad]="news.fields.mainImage.fields.file.url" /></a>
    </div>
    <div class="col-xs-12 col-sm-7">
      <a routerLink="/news/{{ news.fields.slug }}">
        <h5>{{ news.fields.title}} {{ news.fields.displayYear }}</h5>
      </a>
      <h5>{{ news.fields.subtitle2}}</h5>
      <div [innerHTML]="news.fields.introContent | mdToHtml"></div>
    </div>
  </section>
</div>
