<div class="container">
    <section class="padding-bottom">
        <div class="row">
            <div class="col-sm-12"><h1 class="page-header details">404 Error</h1></div>
            <div class="col-sm-12"><h1 class="page-header details">Page Not Found</h1></div>
            <br>
            <br>
            <div class="col-sm-12"><h1 class="page-header details"><a href="/index.html">Click here to return to the homepage</a></h1></div>
        </div>
    </section>
</div>