<footer class="footer container" *ngIf="settings">
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <!-- Begin Mailchimp Signup Form -->
      <!-- <div id="mc_embed_signup">
        <form
          action="https://miguel-chevalier.us3.list-manage.com/subscribe/post?u=2fac78bb06c32afff398de54e&amp;id=8facac9fdc"
          method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
          target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">
            <h2 >{{ settings.fields.footerNewsletter }}</h2>

            <div class="mc-field-group">
              <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Email">
            </div>
            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style="display:none"></div>
              <div class="response" id="mce-success-response" style="display:none"></div>
            </div>
            
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                name="b_2fac78bb06c32afff398de54e_8facac9fdc" tabindex="-1" value=""></div>
            <div class="clear"><input type="submit" value="OK" name="subscribe" id="mc-embedded-subscribe"
                class="button"></div>
          </div>
        </form>
      </div> -->

      <div id="mc_embed_shell">

        <div id="mc_embed_signup">
          <form
            action="https://miguel-chevalier.us3.list-manage.com/subscribe/post?u=2fac78bb06c32afff398de54e&amp;id=8facac9fdc&amp;f_id=002557e2f0"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
            target="_self" novalidate="">
            <div id="mc_embed_signup_scroll">
              <h2>{{ settings.fields.footerNewsletter }}</h2>

              <div class="mc-field-group"><input title="Email" placeholder="Email" type="email" name="EMAIL"
                  class="required email" id="mce-EMAIL" required="" value=""><span id="mce-EMAIL-HELPERTEXT"
                  class="helper_text"></span></div>
              <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display: none;"></div>
                <div class="response" id="mce-success-response" style="display: none;"></div>
              </div>
              <div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text"
                  name="b_2fac78bb06c32afff398de54e_8facac9fdc" tabindex="-1" value=""></div>
              <div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button"
                  value="OK"></div>
            </div>
          </form>
        </div>
      </div>


      <!--End mc_embed_signup-->

    </div>
    <div class="col-sm-12 col-md-6 text-right">
      <div class="link-social">
        <a href="http://vimeo.com/channels/miguelchevalier" target="_blank"><img src="../../assets/img/vimeo-icon-w.svg" alt="vimeo"></a>
        <a href="http://www.facebook.com/Miguel.Chevalier.studio" target="_blank"><img src="../../assets/img/facebook-icon-w.svg" alt="facebook"></a>
        <a href="http://twitter.com/ChevalierMiguel" target="_blank"><img src="../../assets/img/twitter-icon-w.svg" alt="twitter"></a>
        <a href="https://www.youtube.com/user/claudemossessian" target="_blank"><img src="../../assets/img/youtube-icon-w.svg" alt="youtube"></a>
        <a href="https://instagram.com/miguel_chevalier/" target="_blank"><img src="../../assets/img/instagram-icon-w.svg" alt="instagram"></a>
      </div>
    </div>

    <div #footerlang id="footerlang">
      <a data-lang="en-US" [ngClass]="{'active': locale == 'en-US'}"
        (click)="langSelect(footerlangEn.getAttribute('data-lang'))" #footerlangEn>En </a> /
      <a data-lang="fr" [ngClass]="{'active': locale == 'fr'}"
        (click)="langSelect(footerlangFr.getAttribute('data-lang'))" #footerlangFr> Fr</a>
    </div>

  </div>
  <div class="row content-bottom">
    <div class="col-xs-12">
      © Miguel Chevalier 2020
    </div>
  </div>

</footer>