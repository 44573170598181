import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicationListComponent } from './publication-list/publication-list.component';
import { NewsListComponent } from './news-list/news-list.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { TextsComponent } from './texts/texts.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { BiographyPageComponent } from './biography-page/biography-page.component';
import { WorksListComponent } from './works-list/works-list.component';
import { WorksDetailsComponent } from './works-details/works-details.component';
import { HomepageComponent } from './homepage/homepage.component';
import { SearchComponent } from './search/search.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { VideoListComponent } from './video-list/video-list.component';
import { VideoDetailsComponent } from './video-details/video-details.component';


const routes: Routes = [
  { path: '',  component: HomepageComponent},
  { path: 's', component: SearchComponent},
  { path: 'publications', component: PublicationListComponent},
  { path: 'newslist', component: NewsListComponent},
  { path: 'news/:id', component: NewsDetailsComponent },
  { path: 'texts', component: TextsComponent},
  { path: 'contact', component: ContactPageComponent},
  { path: 'biography', component: BiographyPageComponent},
  { path: 'works', component: WorksListComponent},
  { path: 'work/:id', component: WorksDetailsComponent },
  { path: 'videos', component: VideoListComponent },
  { path: 'video/:id', component: VideoDetailsComponent },
  { path: 'fr', redirectTo: '', component: HomepageComponent},
  { path: 'en', redirectTo: '', component: HomepageComponent},
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent},
  { path: 'page', component: PagenotfoundComponent}
  // { path: '**', redirectTo: '', component: HomepageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
