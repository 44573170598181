<div class="container" *ngIf="videolist">
    <section class="row">
      <div class="col-sm-12">
          <h1 class="page-header"><h2>Videos</h2></h1>
      </div>
    </section>
    <section class="underline row" *ngFor="let videos of videolist">
      <div class="col-xs-12 col-sm-5">
        <a routerLink="/video/{{ videos.fields.slug }}"><img alt="videos image" [defaultImage]="defaultImage" [lazyLoad]="videos.fields.mainImage.fields.file.url" /></a>
      </div>
      <div class="col-xs-12 col-sm-7">
        <a routerLink="/video/{{ videos.fields.slug }}">
          <h5>{{ videos.fields.title}}</h5>
        </a>
        <h5>{{ videos.fields.subTitle}}</h5>
        <div [innerHTML]="videos.fields.introContent | mdToHtml"></div>
      </div>
    </section>
  </div>
  