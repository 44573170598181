import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-biography-page',
	templateUrl: './biography-page.component.html'
})
export class BiographyPageComponent implements OnInit {
	
	biography: Entry<any>;
	soloExhibitions: Entry<any>[] = [];
	groupExhibitions: Entry<any>[] = [];
	archDesigns: Entry<any>[] = [];
	monographs: Entry<any>[] = [];
	constructor(
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
		private meta: Meta
	) { }
	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');
		const biographyId = '1MM5V59P1lPoHKMnB9iVGg';
		this.contentfulService.getPage(locale, 'biographyPage', biographyId)
			.then((biography) => {
				this.biography = biography;
				// console.log(this.biography);
			});
		this.contentfulService.getListDESC(locale, 'soloExhibitions', 100)
			.then((soloExhibitions) => {
				this.soloExhibitions = soloExhibitions;
				// console.log(soloExhibitions);
			});
		this.contentfulService.getListDESC(locale, 'groupExhibitions', 100)
			.then((groupExhibitions) => {
				this.groupExhibitions = groupExhibitions;
				// console.log(groupExhibitions);
			});
		this.contentfulService.getListDESC(locale, 'architecturalDesigns', 100)
			.then((archDesigns) => {
				this.archDesigns = archDesigns;
				// console.log(archDesigns);
			});
		this.contentfulService.getListDESC(locale, 'monographs', 100)
			.then((monographs) => {
				this.monographs = monographs;
				// console.log(monographs);
			});
		
		this.title.setTitle('Biographie | Miguel Chevalier');
		this.meta.updateTag({ name: 'description', content: 'Miguel Chevalier' });
	}

	htmlToRichText(richText) {
		if (richText === undefined || richText === null || richText.nodeType !== 'document') {
			return '<p>Error</p>';
		}
		return documentToHtmlString(richText);
	}

}



