import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { Entry } from 'contentful';
import { CookieService } from 'ngx-cookie-service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
	defaultImage = '../../assets/img/lazyload.png';
  	works: Entry<any>[] = [];
	homeCarousel: Entry<any>[] = [];
	settings: Entry<any>;
	constructor(
		private contentfulService: ContentfulService,
		private cookie: CookieService,
		private title: Title,
    	private meta: Meta
	) { 
	
	}

	ngOnInit() {
		if(this.cookie.get('locale') === undefined || !this.cookie.get('locale')){
			this.cookie.set('locale', 'fr');
		}
		var locale = this.cookie.get('locale');

		this.contentfulService.getListWork(locale, 'works', 12)
			.then((works) => {
				this.works = works;
				//console.log(works);rr
			});
    	this.contentfulService.getList(locale, 'homeCarousel')
			.then((homeCarousel) => {
				this.homeCarousel = homeCarousel;
				// console.log(homeCarousel);
			});
		this.title.setTitle('Miguel Chevalier');
		this.meta.updateTag({ name: 'description', content: 'Since 1978, Miguel Chevalier has used computers as a means of expression in the visual arts. He has established himself internationally as one of the pioneers of virtual and digital art' });

		// Get page settings
		this.contentfulService.getPage(locale, 'settings', '2ExNEl8vKOI8qB6MbABmEn')
		.then((settings) => {
			this.settings = settings;
			// console.log(this.settings);
		});
	}

}